import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

const Seo =({ lang, meta, title, description }) => {
    const { site } = useStaticQuery(
        graphql`
          {
          mdx {
            frontmatter {
              description
              title
            }
          }
          site {
            siteMetadata {
              title
              description
              siteUrl
              author
              locale
              og_type
              og_url
              og_title
              og_image
              og_description
              og_locale
            }
          }
    }
    `
    );

    const metaTitle = title ? `${title}` : site.siteMetadata.title;
    const metaDescription = description ? description : site.siteMetadata.description;

    return (
        <Helmet
            htmlAttributes={{
                lang,
            }}
            title={metaTitle}
            meta={[
                {
                    httpEquiv: `Cache-Control`,
                    content: `no-cache, no-store, must-revalidate, max-age=0`,
                },
                {
                    httpEquiv: `Pragma`,
                    content: `no-cache`,
                },
                {
                    httpEquiv: `Expires`,
                    content: `0`,
                },
                {
                    name: `description`,
                    content: metaDescription,
                },
                // Facebook OpenGraph
                {
                    property: `og:type`,
                    content: site.siteMetadata.og_type,
                },
                {
                    property: `og:url`,
                    content: site.siteMetadata.siteUrl,
                },
                {
                    property: `og:title`,
                    // content: metaTitle,
                    content: site.siteMetadata.og_title,
                },
                {
                    property: `og:image`,
                    content: site.siteMetadata.og_image,
                },
                {
                    property: `og:description`,
                    content: site.siteMetadata.og_description,
                },

                {
                    property: `og:locale`,
                    content: site.siteMetadata.locale,
                },
            ]

                .concat(meta)}
        >
            <link rel="alternate" href="https://archeosit.it/" hrefLang="x-default"/>
        </Helmet>
    )
};

Seo.defaultProps = {
    lang: `it`,
    meta: [],
    description: ``,
};

Seo.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    url: PropTypes.string,
    lang: PropTypes.string,
    meta: PropTypes.array,
};

Seo.defaultProps = {
    lang: `it`,
    meta: [],
    description: ``,
};

export default Seo;