import React from "react"
import styled from "styled-components"

import {jsonConfigSito, useFetch} from "../utils/config"

const StyledFooter = styled.section`
  padding:15px;
  color: ${props => props.color ? props.color :  props.theme.color.altwhite};
  background-color: ${props => props.background ? props.background : props.theme.color.black};
  text-align: center;
  letter-spacing: ${props => props.background === props.theme.color.black ? "0.03rem" : "inherit"};
  font-size: 14px;
  
  hr {
    border-bottom: 1px solid ${props => props.color ? props.color :  props.theme.color.altwhite};
  }
`;

const Footer = ({id, background, color, style, className, children}) => {

    return (
        <StyledFooter id={id} background={background} color={color} style={style}>
            <div className={"container"}>
                <section className={`row ${className ? className : ''}`}>
                    {children}
                </section>
            </div>
        </StyledFooter>
    )
};

const Copyright = ({from}) => {
    let currentYear = new Date().getFullYear();
    return (
        <>
            {
            (JSON.stringify(currentYear) !== from) ?
                <>
                &copy; {from} - {new Date().getFullYear()}
                </>
            :
                <>
                &copy; {from}
                </>
            }
        </>
    )
};

const PIva = () => {
    const { data } = useFetch(jsonConfigSito, {});
    const { footer: { nome, indirizzo, partitaIVA } = { nome: "", indirizzo: "", partitaIVA: "" } } = data;
    return (
        <>
            {(nome !== undefined && nome !== "") ?
            <span className="m-0 mx-1 font-weight-600 d-block d-md-inline-block"> <span className="d-none d-md-inline-block">|</span> {nome}</span>
                :
                ""
            }
            {(indirizzo !== undefined && indirizzo !== "") ?
            <span className="m-0 d-block d-md-inline-block">{indirizzo}</span>
                :
                ""
            }
            {(partitaIVA !== undefined && partitaIVA !== "") ?
            <span className="mb-1 mx-1 font-weight-600 d-block d-md-inline-block">
                <span className="d-none d-md-inline-block">|</span> P.IVA: {partitaIVA} <span className="d-none d-md-inline-block">|</span>
            </span>
                :
                ""
            }
        </>
    );
};

const Versione = () => {
    const { data } = useFetch(jsonConfigSito, {});
    const { footer: { versione } = { versione: "" } } = data;
    return (
        <>
            {versione}
        </>
    )
};

const Patamu = () => {
    const { data } = useFetch(jsonConfigSito, {});
    const { footer: { patamu } = { patamu: "" } } = data;
    return (
        <>
        {(patamu !== undefined && patamu !== "") ?
            <>
            <div>
                Tutti i contenuti del sito sono tutelati dal plagio su <b>patamu.com</b> con numero deposito <b>{patamu}</b>
            </div>
            </>
            :
            ""
        }
        </>
    );

};

export {Footer, Copyright, PIva, Versione, Patamu}
