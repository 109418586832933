import React from "react"
import styled from 'styled-components'

import theme from "./src/style/theme"
import Menu from "./src/components/menu.js"
import * as Section from "./src/components/splitSections.js"
import Anchor from "./src/components/anchor.js"
import {Hero} from "./src/components/hero"
import {Footer, Copyright, PIva, Versione, Patamu} from "./src/components/footer.js"
import Seo from "./src/components/seo.js"


const Wrapper = styled.div`
    margin-top: ${theme.header.height};
    padding-top: "10px";
    display: flex;
    min-height: calc(100vh - ${props => props.theme.header.height});
    flex-direction: column;
    
    & .site-content {
        flex: 1 0 auto;
    }
`;

const Index = ({ children, location, pageContext }) => {
    const { title, description, h1, h2, heroImage } = pageContext.frontmatter;
    // console.log(title, description);
    return (
        <Wrapper id="top">
                    <Seo title={title} description={description} />
                    <Menu location={location} />
                    <Hero classicHero contentPadding="5" image backgroundColor="#d59f59" h1={h1} h2={h2} heroImage={heroImage} />
                    <main className="site-content">
                        {children}
                    </main>
                    <Footer background={"#b8b8b8"} color={"#050401"}>
                        <Section.FullWidth>
                            <Copyright from="2022" />
                            <PIva />
                            <Anchor href="https://zenitsecurity.s3.eu-south-1.amazonaws.com/websites/archeosit/extra/privacy.pdf" title={"Scarica il PDF con la Privacy Policy"}>Privacy e Cookie Policy</Anchor>
                            <hr />
                            <p className="mb-1">Progettazione e sviluppo <Anchor external="true" href="https://www.zenitsecurity.com" title="Vai al sito di Zenit Security">Zenit Security</Anchor></p>
                            <Versione/>
                            <Patamu />
                        </Section.FullWidth>

                    </Footer>
        </Wrapper>
    )
};

export default Index;