import React, { useEffect, useState } from 'react'
import { useScrollYPosition } from 'react-use-scroll-position'
import styled from 'styled-components'
import { AnchorLink } from 'gatsby-plugin-anchor-links'

import LogoImg from "../images/logo.svg"

const HeaderWrapper = styled.nav`
  background: ${props => props.theme.color.babypowder};
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;    
    
  section {
    height: ${props => props.theme.header.height};
    align-items: center;
    justify-content: space-between;
    transition: height 0.3s ease;
    .logo {
        a {
            text-decoration: none;
            color: ${props => props.theme.color.black}
        }
    }
    .nav-m {
       line-height: 1rem;
      :focus {
        outline: none;
      }
      .menu_label {
        display: block;
        font-size: 15px;
        font-weight: 600;
        text-align: center;
        cursor: pointer;
      }
      &.open {
        button {
          div {
            background: ${props => props.theme.color.black};
            &.second {
              opacity: 0;
            }
            &.first {
              transform: rotate(45deg);
              top: .95rem;
            }
            &.third {
              transform: rotate(-45deg);
              bottom: .1rem;
            }
          }
        }
      }
      button {
        height: 1.2rem;
        width: 2.5rem;
        position: relative;
        background: transparent;
        opacity: .7;
        padding: 0;
        border: none;
        div {
          transition: all 0.2s;
          height: 3px;
          width: 100%;
          background: #1d1d1b;
          position: absolute;          
          &.first {
            top: 0;
            left: 0;
          }
          &.second {
            top: calc(50% - 1.5px);
            left: 0;
          }
          &.third {
            bottom: 0;
            left: 0;
          }
        }
      }
    }
    .nav-d {
      display: none;
      span {
        color: ${props => props.theme.color.gray};
        font-size: .9rem;
        line-height: 2.5rem;
        font-weight: 500;
        margin-right: 3px;
      }    
      a, button {
        color: ${props => props.theme.color.black};
        font-size: .9rem;
        line-height: 2.2rem;
        font-weight: ${props => props.theme.font.medium};
        margin-left: .1rem;
        margin-bottom: .4rem;
        position: relative;
        text-decoration: none;
        transition: all 0.3s ease;
        padding: 0.2rem 0.4rem;
        z-index: 10;
        &:hover {
            color: ${props => props.theme.color.rust};
          &::before {
            height: 100%;
            width: 100%;
            opacity: 1;
            border-radius: 8px;
          }
        }
        &:before {
          z-index: -1;
          content: '';
          position: absolute;
          height: 0%;
          width: 100%;
          bottom: 0;
          left: 0;
          opacity: 1;
          transition: all 0.3s ease;
          background: ${props => props.theme.color.gray};
        }
      }
      .accent-link-menu {
        background-color: #59a139;
        color: #fff!important;
        border-radius: 8px;
      }
      .accent-link-menu-2 {
        background-color: #4d6a79;
        color: #fff!important;
        border-radius: 8px;
      }
      .divider {
        position: relative;
        margin-right: 2rem;
        &::after {
          content: '';
          height: 3rem;
          width: 0.1rem;
          background: ${props => props.theme.color.orange};
          position: absolute;
          top: -0.3rem;
          right: -1rem;
        }
      }
    }
    .nav-mm {
      align-items: center;
    
      font-size: 1rem;
      line-height: 2rem;
      // text-transform: uppercase;
      transform: translateX(${props => (props.open ? '0%' : '100%')});
      transition: transform 0.3s ease;
      display: flex;
      flex-direction: column;
      position: fixed;
      top: ${props => props.theme.header.height};
      left: 0;
      width: 100%;
      background: white;
      height: calc(100vh - ${props => props.theme.header.height});
      padding: 1.1rem;
      @media (min-width: ${props => props.theme.screen.sm}) {
        padding: 0 2.2rem;
      }
      a {
        font-family: ${props => props.theme.font.secondary};
        font-weight: ${props => props.theme.font.medium};
        color: ${props => props.theme.color.black};
        margin-bottom: 0.4rem;
        text-decoration: none;
        &.divider {
          margin-bottom: 2rem;
          position: relative;
          z-index: -99;
          &:after {
            content: '';
            position: absolute;
            bottom: -1rem;
            left: 0;
            width: 100%;
            height: 0.2rem;
            background: ${props => props.theme.color.orange};
          }
        }
      }
      .accent-link-menu {
        background-color: #59a139;
        color: #fff!important;
        padding: 5px 10px;
        border-radius: 8px;
        text-transform: uppercase;
        font-family: ${props => props.theme.font.secondary};
        font-weight: ${props => props.theme.font.extrabold};
        color: ${props => props.theme.color.black};
        margin-bottom: 0.2rem;
      }
      .accent-link-menu-2 {
        background-color: #4d6a79;
        color: #fff!important;
        padding-left: 10px;
        padding-right: 10px;
        border-radius: 8px;
      }
    }
    @media (min-width: ${props => props.theme.screen.lg}) {
      .nav-m {
        display: none;
      }
      .nav-d {
        display: flex;
      }
      .nav-mm {
        display: none;
      }
    }
  }
`;

const HeaderMenuMask = styled.div`
  background: white;
  position: absolute;
  top: 0;
  right: 0;
  width: ${props => (props.triggered ? '100%' : '0%')};
  transition: 0.6s all;
  z-index: -1;
  height: 100%;
`;

const HeaderScrolledMask = styled.div`
  background: white;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  transition: width 0.3s ease;
  z-index: -1;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  width: ${props => (props.triggered ? '100%' : '0%')};
`;

const StyledLogo = styled.img`
  width: 236px;
  height: 58px;
`;

const Logo = () => (
    <>
        <StyledLogo src={LogoImg} alt={"ArcheoSIT"} />
    </>
);

const Container = ({ children }) => (
    <section className="container d-flex">{children}</section>
);

const Menu = ({ location }) => {
    const [open, setOpen] = useState(false);

    const scrollY = useScrollYPosition();
    const scrolled = scrollY !== 0;

    useEffect(() => {
        setOpen(false);
    }, [location, scrollY]);


    function renderLinks() {
        return (
            <>
                {typeof window !== "undefined"
                    &&
                    <>
                    <AnchorLink to="/#top" stripHash>Home</AnchorLink>
                    <AnchorLink to="/#sezione1" stripHash>Perché ArcheoSIT</AnchorLink>
                    <AnchorLink to="/#sezione2" stripHash>Cosa facciamo</AnchorLink>
                    <AnchorLink to="/#sezione3" stripHash>Servizi</AnchorLink>
                    <AnchorLink to="/#sezione4" stripHash>Archivio Storico</AnchorLink>
                    <AnchorLink to="/#sezione5" stripHash>Forma Urbis Ennae</AnchorLink>
                    <AnchorLink to="/#contatti" className="divider" stripHash>Contatti</AnchorLink>
                    <AnchorLink to="/editoria#top" stripHash>Editoria</AnchorLink>
                    <AnchorLink to="/team#top" stripHash>Team</AnchorLink>
                    </>
                }
            </>
        );
    }

    return (
        <HeaderWrapper triggered={scrolled} open={open}>
            <HeaderMenuMask triggered={open} />
            <HeaderScrolledMask triggered={scrolled} />
            <Container>
                <div className="logo">
                    <AnchorLink to="/#top" stripHash>
                        <Logo />
                    </AnchorLink>
                </div>
                <div className="nav-d">{renderLinks()}</div>
                <div className={`nav-m${open ? ' open' : ''}`} role="button" tabIndex={0} onClick={() => setOpen(!open)} onKeyDown={() => setOpen(!open)}>
                    <span className="menu_label">MENU</span>
                    <button
                        aria-label="Toggle Menu"
                        className="icon"
                    >
                        <div className="first" />
                        <div className="second" />
                        <div className="third" />
                    </button>
                </div>
                <div className="nav-mm">{renderLinks()}</div>
            </Container>
        </HeaderWrapper>
    );
};

export default Menu;